<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Completion Badge for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceCompletionBadge" />
      </v-card-title>

      <v-card-text>      
        Configure automatic completion badge for this leaderboard.
      </v-card-text>
      <v-alert v-if="request" type="success" tile>
        Completion badge is enabled for this leaderboard.
      </v-alert>

      <BadgeDialog ref="badgeDialog" :event="event" :seriesEvents="null" :race="race" :badges="badges" @saved="getProfile" />

      <v-card-text v-if="request">
        <!-- <v-btn color="primary" large @click="$refs.badgeDialog.openDialog(request)">Configure</v-btn> -->
        <v-btn color="danger" text @click="deleteBadge">Disable Completion Badge</v-btn>
      </v-card-text>

      <v-card-text v-if="!request">
        <v-btn color="primary" large @click="createBadge">Enable completion badge</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';

import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import BadgeDialog from './_BadgeDialog.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceCompletionBadge",
  components: {
    Header,
    RaceSelector,
    BadgeDialog,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      race: null,
      badges: null,
      request: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.badges = (await eventManagerService.getBadges(id, raceId)).data.data;
      this.request = this.race.completion_badge;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async createBadge() {
      this.request = {};
      await this.save();
    },

    async deleteBadge() {
      this.request = null;
      await this.save();
    },
    
    async save() {
      const response = (await eventManagerService.putRaceCompletionBadge(this.event.id, this.race.id, this.request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved.');

      await this.getProfile();
    },


  },
  computed: {
    hasMapBadges() {
      return this.badges && this.badges.some(x => x.show_map);
    },
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Completion Badge', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

